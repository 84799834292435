import { useEffect } from "react";
import store, { useAppSelector } from "../redux/store";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import OrderArticle, { getTotalPrice } from "../models/order/OrderArticle";

export default function useAnalytics() {
  const gaAnalyticsKey = useAppSelector((state) => state.global.sessionState?.ga);
  const analyticsEnabled = useAppSelector((state) => !state.dev.disableAnalytics);

  useEffect(() => {
    if (analyticsEnabled && gaAnalyticsKey !== undefined && gaAnalyticsKey) {
      /**
       * GA3/GA4
       */
      if (String(gaAnalyticsKey).substring(0, 2) === "UA") {
        /**
         * GA3
         */
        ReactGA.initialize(gaAnalyticsKey);
      } else {
        ReactGA4.initialize([
          {
            trackingId: gaAnalyticsKey,
            gaOptions: {
              name: "Analytics",
            },
          },
        ]);
      }
    }
  }, [analyticsEnabled, gaAnalyticsKey]);
}

export function postAnalyticsEvent(eventData: ReactGA.EventArgs) {
  const analyticsEnabled = !store.getState().dev.disableAnalytics;
  const gaAnalyticsKey = store.getState().global.sessionState?.ga;

  if (analyticsEnabled) {
    /**
     * GA3/GA4
     */
    if (gaAnalyticsKey && gaAnalyticsKey.substring(0, 2) === "UA") {
      /**
       * GA3
       */
      ReactGA.event(eventData);
    } else {
      ReactGA4.event(eventData);
    }
  }
}

export function postEcomAnalyticsEvent(ecomType: string, ecomData: object) {
  const analyticsEnabled = !store.getState().dev.disableAnalytics;
  const gaAnalyticsKey = store.getState().global.sessionState?.ga;

  if (analyticsEnabled) {
    if (gaAnalyticsKey?.substring(0, 2) !== "UA") {
      ReactGA4.gtag("event", ecomType, { ...ecomData, currency: store.getState().menu.currency });
    }
  }
}

export function getOptionData(orderArticle: OrderArticle) {
  return orderArticle.orderOptionGroups
    .map((orderOptionGroup) => {
      return orderOptionGroup.orderArticles
        .filter((OptionArticle) => OptionArticle.count > 0)
        .map((OptionArticle: OrderArticle) => {
          return OptionArticle.article.name;
        });
    })
    .flat()
    .join();
}

export function submitEComEvent(
  ecomEvent: string,
  autoAddedItems: OrderArticle[],
  items: OrderArticle[],
  voucherOrderItems: OrderArticle[],
  priceLineId: number | null,
  extraParams: object = {}
) {
  const currency = store.getState().menu.currency;
  /**
   * Anayltics ecom data
   */
  const ecomAnalyticsItems: object[] = [];
  autoAddedItems.forEach((orderArticle) => {
    ecomAnalyticsItems.push({
      item_id: orderArticle.article.id,
      item_name: orderArticle.article.name,
      item_variant: getOptionData(orderArticle),
      price: getTotalPrice(orderArticle, priceLineId),
      quantity: orderArticle.count,
    });
  });
  items.forEach((orderArticle) => {
    ecomAnalyticsItems.push({
      item_id: orderArticle.article.id,
      item_name: orderArticle.article.name,
      item_variant: getOptionData(orderArticle),
      price: getTotalPrice(orderArticle, priceLineId),
      quantity: orderArticle.count,
    });
  });
  voucherOrderItems.forEach((orderArticle) => {
    ecomAnalyticsItems.push({
      item_id: orderArticle.article.id,
      item_name: orderArticle.article.name,
      item_variant: getOptionData(orderArticle),
      price: getTotalPrice(orderArticle, priceLineId),
      quantity: orderArticle.count,
    });
  });
  postEcomAnalyticsEvent(ecomEvent, {
    ...extraParams,
    currency: currency,
    items: ecomAnalyticsItems,
  });
}
