import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

import { appInitialized } from "../redux/globalSlice.tsx";
import { orderSucceeded } from "../redux/shoppingCartSlice.tsx";
import OrderArticle from "../models/order/OrderArticle.ts";
import { kioskResetted } from "../redux/kioskSlice.tsx";
import _ from "lodash";
import { JamezzPiggyReward } from "../redux/api/piggyRewardsApi.ts";

export type ProductDto = {
  id: number;
  maxCount: number;
  optionGroups?: OptionGroupDto[];
};
export type OptionGroupDto = {
  id: number;
  options?: ProductDto[];
  allOptionsCount?: number;
  maxCount?: number;
};

export type VoucherDefAmountOffProduct = {
  id?: number;
  discountType: "AMOUNT_OFF_PRODUCT";
  priceDiscountAmount: number;
  freeProducts_JSON: string[];
  includedProducts_JSON: string[];
  included_products: { apiId1: string | null; apiId2: string | null; id: number; naam: string }[];
  maxChooseItems: number;
  max_discounted_amount: number;
  applied_on_product_ids_through_menus: string[];
  supergroups: { supergroups: { id: string }[] };
  discounted_products: ProductDto[];
} & VoucherDefV2Base;

export type VoucherDefPercentageOffProduct = {
  id?: number;
  discountType: "PERCENTAGE_OFF_PRODUCT";
  priceDiscountPercentage: number;
  freeProducts_JSON: string[];
  includedProducts_JSON: string[];
  included_products: { apiId1: string | null; apiId2: string | null; id: number; naam: string }[];
  maxChooseItems: number;
  applied_on_product_ids_through_menus: string[];
  supergroups: { supergroups: { id: string }[] };
  discounted_products: ProductDto[];
} & VoucherDefV2Base;

export type VoucherDefAmountOffTotal = {
  id?: number;
  discountType: "AMOUNT_OFF_TOTAL";
  priceDiscountAmount: number;
  freeProducts_JSON: string[];
} & VoucherDefV2Base;

export type VoucherDefPercentageOffTotal = {
  id?: number;
  discountType: "PERCENTAGE_OFF_TOTAL";
  priceDiscountPercentage: number;
  freeProducts_JSON: string[];
} & VoucherDefV2Base;

export type VoucherDefCollection = {
  id?: number;
  discountType: "COLLECTION";
} & VoucherDefV2Base;

export type VoucherDefAddDiscountedProduct = {
  id?: number;
  discountType: "ADD_DISCOUNTED_PRODUCT";
  freeProducts_JSON: string[];
  free_products_v2: { apiId1: string | null; apiId2: string | null; id: number; naam: string }[];
} & VoucherDefV2Base;

export type VoucherDefV2Base = { active: 0 | 1; blocked: 0 | 1; title: string; minBasketPrice?: number };

export type VoucherDefV2 =
  | VoucherDefAmountOffTotal
  | VoucherDefPercentageOffTotal
  | VoucherDefAmountOffProduct
  | VoucherDefPercentageOffProduct
  | VoucherDefAddDiscountedProduct
  | VoucherDefCollection;

export type VoucherV2 = {
  voucher: {
    code: string; // May not be undefined. Backend will break things without a code.
    piggyReward?: Pick<JamezzPiggyReward, "id" | "rewardable_type" | "rewardable_id" | "piggy_uuid">;
    number_of_times: number;
    total_redemptions_allowed?: number;
    times_redeemed?: number;
  };
  voucherdef: VoucherDefV2;
  orderArticles?: OrderArticle[];
  subVouchers?: VoucherV2[];
};

interface VoucherV2State {
  vouchers: VoucherV2[];
  addDiscountedProductVouchers: VoucherV2[];
}

const initState: VoucherV2State = {
  vouchers: [],
  addDiscountedProductVouchers: [],
};

function resetVouchersV2(state: Draft<VoucherV2State>) {
  state.vouchers = [];
  state.addDiscountedProductVouchers = [];
  localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
}

function vouchersAdded(vouchers: VoucherV2[], state: Draft<VoucherV2State>) {
  vouchers.forEach((voucherV2) => {
    const voucherIndex = state.vouchers.findIndex(
      (voucher) => voucher.voucher.code === voucherV2.voucher.code && voucher.voucherdef.id === voucherV2.voucherdef.id
    );
    const voucher = _.cloneDeep(voucherV2);
    if (voucherIndex >= 0) {
      voucher.voucher.number_of_times += state.vouchers[voucherIndex].voucher.number_of_times;
      state.vouchers.splice(voucherIndex, 1, voucher);
    } else {
      state.vouchers.push(voucherV2);
    }

    if (
      voucherV2.voucherdef.discountType === "ADD_DISCOUNTED_PRODUCT" ||
      ((voucherV2.voucherdef.discountType === "PERCENTAGE_OFF_PRODUCT" ||
        voucherV2.voucherdef.discountType === "AMOUNT_OFF_PRODUCT") &&
        voucherV2.voucherdef.maxChooseItems > 0)
    ) {
      state.addDiscountedProductVouchers.push(voucherV2);
    }
    voucherV2.subVouchers?.forEach((subVoucher) => {
      if (
        subVoucher.voucherdef.discountType === "ADD_DISCOUNTED_PRODUCT" ||
        ((subVoucher.voucherdef.discountType === "PERCENTAGE_OFF_PRODUCT" ||
          subVoucher.voucherdef.discountType === "AMOUNT_OFF_PRODUCT") &&
          subVoucher.voucherdef.maxChooseItems > 0)
      ) {
        state.addDiscountedProductVouchers.push(subVoucher);
      }
    });
  });

  localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
}

function vouchersRemoved(vouchers: VoucherV2[], state: Draft<VoucherV2State>) {
  vouchers.forEach((voucherV2) => {
    const indexToBeRemoved = state.vouchers.findIndex(
      (voucher) => voucher.voucher.code == voucherV2.voucher.code && voucher.voucherdef.id === voucherV2.voucherdef.id
    );
    state.vouchers.splice(indexToBeRemoved, 1);
  });

  localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
}

function numberOfTimesChanged(
  voucherV2s: { voucherCode: string; numberOfTimes: number }[],
  state: Draft<VoucherV2State>
) {
  voucherV2s.forEach((voucherV2) => {
    const index = state.vouchers.findIndex((voucher) => voucher.voucher.code == voucherV2.voucherCode);
    state.vouchers[index].voucher.number_of_times = voucherV2.numberOfTimes;
  });

  localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
}

function numberOfTimesSum(voucherV2s: { voucherCode: string; numberOfTimes: number }[], state: Draft<VoucherV2State>) {
  voucherV2s.forEach((voucherV2) => {
    const index = state.vouchers.findIndex((voucher) => voucher.voucher.code == voucherV2.voucherCode);
    state.vouchers[index].voucher.number_of_times += voucherV2.numberOfTimes;
  });

  localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
}

export const vouchersV2Slice = createSlice({
  name: "vouchersV2",
  initialState: initState,
  reducers: {
    vouchersV2Added: (state, action: PayloadAction<VoucherV2[]>) => {
      vouchersAdded(action.payload, state);
    },
    vouchersV2NumberOfTimesSum: (state, action: PayloadAction<{ voucherCode: string; numberOfTimes: number }[]>) => {
      numberOfTimesSum(action.payload, state);
    },
    vouchersV2NumberOfTimesChanged: (
      state,
      action: PayloadAction<{ voucherCode: string; numberOfTimes: number }[]>
    ) => {
      numberOfTimesChanged(action.payload, state);
    },
    vouchersV2Removed: (state, action: PayloadAction<VoucherV2[]>) => {
      vouchersRemoved(action.payload, state);
    },
    voucherOrderArticleConfirmed: (
      state,
      action: PayloadAction<{ orderArticle?: OrderArticle; voucher: VoucherV2 }>
    ) => {
      function findAddDiscountedVoucher(vouchers: VoucherV2[], targetCode: string): VoucherV2 | null {
        for (const voucher of vouchers) {
          if (voucher.voucher.code == targetCode && (!voucher.orderArticles || voucher.orderArticles.length === 0)) {
            return voucher;
          } else if (voucher.subVouchers) {
            const found = findAddDiscountedVoucher(voucher.subVouchers, targetCode);
            if (found) {
              return found;
            }
          }
        }
        return null;
      }

      const voucher = findAddDiscountedVoucher(state.vouchers, action.payload.voucher.voucher.code);
      if (action.payload.orderArticle && voucher) {
        if (!voucher.orderArticles) {
          voucher.orderArticles = [];
        }
        voucher.orderArticles.push(action.payload.orderArticle);
      }
      state.addDiscountedProductVouchers.splice(0, 1);
    },
    voucherOrderArticleCanceled: (state, action: PayloadAction<{ voucher: VoucherV2 }>) => {
      vouchersRemoved([action.payload.voucher], state);
      state.addDiscountedProductVouchers.splice(0, 1);
    },
    vouchersDeclined: (state) => {
      resetVouchersV2(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(appInitialized, (state) => {
        const vouchersCartJson = localStorage.getItem("V5.vouchersV2.vouchers");
        let vouchersCart: VoucherV2[] = [];
        if (vouchersCartJson != null) {
          vouchersCart = JSON.parse(vouchersCartJson) as VoucherV2[];
        }

        state.vouchers = vouchersCart.map((voucher) => {
          //// Make state backwards compatible
          if (voucher.voucher.number_of_times == null) {
            voucher.voucher.number_of_times = 1;
          }
          return voucher;
        });
      })
      .addCase(orderSucceeded, (state) => {
        resetVouchersV2(state);
      })
      .addCase(kioskResetted, (state) => {
        resetVouchersV2(state);
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  vouchersV2Added,
  vouchersV2Removed,
  vouchersV2NumberOfTimesChanged,
  vouchersV2NumberOfTimesSum,
  voucherOrderArticleConfirmed,
  vouchersDeclined,
  voucherOrderArticleCanceled,
} = vouchersV2Slice.actions;

export default vouchersV2Slice.reducer;
