import { useCallback } from "react";
import { httpGet } from "./http";

import { setAppStyling, setSalesarea, setServerTimestamp } from "../redux/globalSlice";
import { defaultCurrency, setCurrency } from "../redux/menuSlice";
import store, { useAppDispatch } from "../redux/store";
import useParseCurrencyRates from "./parseApis/useParseCurrencyRates";
import currencyCodes from "currency-codes";
import _ from "lodash";

export default function useSalesareaData() {
  const dispatch = useAppDispatch();
  const parseCurrencyRates = useParseCurrencyRates();

  const getSalesareaData = useCallback(() => {
    return httpGet("/v5_2/qr/salesarea-fetch", null, { showSpinner: false, showToastError: false })
      .then((response: any) => {
        _.defer(() => {
          {
            /**
             * Each response contains a timestamp.
             * If there is no timestamp, something is very wrong with our backend.
             */
            const { timestamp } = response.data;
            try {
              dispatch(setServerTimestamp(timestamp));
            } catch (err) {
              return err;
            }
          }

          const state = store.getState();
          const salesarea = response.data.data.salesarea;
          {
            /**
             * This block handles custom data and app styling
             */
            let customData;
            try {
              customData = JSON.parse(salesarea.custom_data);
            } catch (_e) {
              customData = {};
            }
            if (customData.kiosk?.enable_product_barcode_scanner === undefined) {
              if (customData.kiosk == null) {
                customData.kiosk = { enable_product_barcode_scanner: true };
              } else {
                customData.kiosk.enable_product_barcode_scanner = true;
              }
            }
            salesarea.custom_data = customData;
            const appStyling = {
              "JS-QrTheme": customData?.["JS-QrTheme"] ?? {},
              "JS-QrGlobal": customData?.["JS-QrGlobal"] ?? {},
            };
            if (!_.isEqual(appStyling, state.global.appStyling)) {
              dispatch(setAppStyling(appStyling));
            }
            if (!_.isEqual(salesarea, state.global.salesarea)) {
              dispatch(setSalesarea(salesarea));
            }
          }

          {
            /**
             * This block handles currency... obviously
             */
            const currency = _.mapKeys(currencyCodes.codes(), (val) => val)[salesarea?.valuta] ?? defaultCurrency;
            if (currency !== state.menu.currency) {
              dispatch(setCurrency(currency));
            }
            parseCurrencyRates(response.data.data.currency_rates);
          }
        });
      })
      .catch((e) => {
        if (e?.response?.data?.message === "Ip not allowed") {
          window.location.href = "/ipnotallowed";
        }
      });
  }, [dispatch, parseCurrencyRates]);

  return { getSalesareaData };
}
