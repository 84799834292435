import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Articlegroup from "../models/menu/Articlegroup";
import CurrencyRate from "../models/currencies/CurrencyRate";
import { RootState } from "./store";
import { selectSupergroupsMap } from "./selectors/selectSupergroupsMap";

interface MenuState {
  userSelectedSupergroupId: string;

  selectedArticlegroupIds: Record<string, boolean>;

  selectedArticlegroup: Articlegroup | null;
  priceKey: string;
  currencyRates: Record<string, CurrencyRate>;
  currency: string;
}

export const defaultCurrency: string = "EUR";

const initState: MenuState = {
  currencyRates: {},
  userSelectedSupergroupId: "",
  selectedArticlegroupIds: {},
  selectedArticlegroup: null,
  priceKey: "",
  currency: defaultCurrency,
};

export const menuSlice = createSlice({
  name: "menu",
  initialState: initState,
  reducers: {
    setSelectedSupergroupId: (state, action) => {
      state.userSelectedSupergroupId = action.payload;
    },
    setSelectedArticlegroup: (state, action: PayloadAction<{ articlegroup: Articlegroup | null }>) => {
      state.selectedArticlegroup = action.payload.articlegroup;

      state.selectedArticlegroupIds = {};
      if (action.payload.articlegroup?.id) {
        state.selectedArticlegroupIds[action.payload.articlegroup.id] = true;
      }
    },
    setPriceKey: (state, action) => {
      state.priceKey = action.payload;
    },
    setCurrencyRates: (state, action: PayloadAction<Record<string, CurrencyRate>>) => {
      state.currencyRates = action.payload;
    },
    setCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedSupergroupId, setSelectedArticlegroup, setPriceKey, setCurrencyRates, setCurrency } =
  menuSlice.actions;

export const selectSelectedSupergroupId = createSelector(
  [(state: RootState) => state.menu.userSelectedSupergroupId, selectSupergroupsMap],
  (userSelectedSupergroupId, supergroupsMap) => {
    if (userSelectedSupergroupId) {
      return userSelectedSupergroupId;
    } else {
      return Object.values(supergroupsMap).sort((a, b) => a.sortKey - b.sortKey)[0]?.id ?? "";
    }
  }
);
export default menuSlice.reducer;
